@media (max-width:600px) {
body {
    font-family: sans-serif;
  }
  
  input {
    border: 1px solid #999;
    padding: 0.5rem;
    z-index: 99;
    width: 45%;
    position: relative;
  }
  
  .no-suggestions {
    color: #999;
    padding: 0.5rem;

    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  
  .suggestions {
    /* border: 1px solid #999; */
    border-top-width: 0;
    list-style:none;
    margin-top: 0;
    position:relative;
    max-height: 143px;
    overflow-y: auto;

    padding-left: inherit;
    padding-right: inherit;

    /* width: calc(300px + 1rem); */
    width: 100%;
    background-color: #fff;
    /* border-bottom: 1px solid #d4d4d4; */
    border-radius: 20px;
    display: inline;
    justify-content: center;
    align-items: center;
    z-index: 99;

  }
  
  .suggestions li {
    padding: 0.5rem;
    justify-content: center;
    justify-self: center;
    align-items: center;
    position: relative;
    display: block;
    text-align: center;
    width: 100%;
    /* padding-left: inherit; */
    background-color: #fff;
    /* border-bottom: 1px solid #d4d4d4; */
    border-radius: 20px;
    z-index: 99;
    
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color:whitesmoke;
    border-bottom: 2px solid #d4d4d4;
    border-radius: 20px;
    color: black;
    cursor: pointer;
    justify-content: center;
    justify-self: center;
    position: relative;
    font-weight: 700;
    z-index: 99;
  }
  
  .suggestions li:not(:last-child) {
    border-bottom: 2px solid #999;
    border-left: 1px solid #999;
    border-right: 1px solid #999;
    width: 95%;
    z-index: 99;
    padding-left: 1%;
  }
  .suggestions li:last-of-type {
    border-bottom: 2px solid #999;
    border-left: 1px solid #999;
    border-right: 1px solid #999;
    width: 95%;
    z-index: 99;
    padding-left: 1%;
  }
}
















@media (min-width:601px) and (max-width:1400px) {
  body {
      font-family: sans-serif;
    }
    
    input {
      border: 1px solid #999;
      padding: 0.5rem;
      z-index: 99;
      width: 45%;
      position: relative;
    }
    
    .no-suggestions {
      color: #999;
      padding: 0.5rem;
  
      justify-content: center;
      align-items: center;
      z-index: 99;
    }
    
    .suggestions {
      /* border: 1px solid #999; */
      border-top-width: 0;
      list-style:none;
      margin-top: 0;
      position:relative;
      max-height: 143px;
      overflow-y: auto;
      font-size: 48px;
      padding-left: inherit;
      padding-right: inherit;
  
      /* width: calc(300px + 1rem); */
      width: 100%;
      background-color: #fff;
      /* border-bottom: 1px solid #d4d4d4; */
      border-radius: 20px;
      display: inline;
      justify-content: center;
      align-items: center;
      z-index: 99;
  
    }
    
    .suggestions li {
      padding: 0.5rem;
      justify-content: center;
      justify-self: center;
      align-items: center;
      position: relative;
      display: block;
      font-size: 55px;
      text-align: center;
      width: 100%;
      /* padding-left: inherit; */
      background-color: #fff;
      /* border-bottom: 1px solid #d4d4d4; */
      border-radius: 20px;
      z-index: 99;
      
    }
    
    .suggestion-active,
    .suggestions li:hover {
      background-color:whitesmoke;
      border-bottom: 2px solid #d4d4d4;
      border-radius: 20px;
      color: black;
      cursor: pointer;
      justify-content: center;
      justify-self: center;
      position: relative;
      font-weight: 700;
      z-index: 99;
    }
    
    .suggestions li:not(:last-child) {
      border-bottom: 2px solid #999;
      border-left: 1px solid #999;
      border-right: 1px solid #999;
      font-size: 25px;
      width: 95%;
      z-index: 99;
      padding-left: 1%;
    }
    .suggestions li:last-of-type {
      border-bottom: 2px solid #999;
      border-left: 1px solid #999;
      border-right: 1px solid #999;
      font-size: 25px;
      width: 95%;
      z-index: 99;
      padding-left: 1%;
    }
  }



















  @media (min-width:1401px) {
    body {
        font-family: sans-serif;
      }
      
      input {
        border: 1px solid #999;
        padding: 0.5rem;
        z-index: 99;
        width: 45%;
        position: relative;
      }
      
      .no-suggestions {
        color: #999;
        padding: 0.5rem;
    
        justify-content: center;
        align-items: center;
        z-index: 99;
      }
      
      .suggestions {
        /* border: 1px solid #999; */
        border-top-width: 0;
        list-style:none;
        margin-top: 0;
        position:relative;
        max-height: 143px;
        overflow-y: auto;
        font-size: 23px;
        padding-left: inherit;
        padding-right: inherit;
    
        /* width: calc(300px + 1rem); */
        width: 100%;
        background-color: #fff;
        /* border-bottom: 1px solid #d4d4d4; */
        border-radius: 20px;
        display: inline;
        justify-content: center;
        align-items: center;
        z-index: 99;
    
      }
      
      .suggestions li {
        padding: 0.5rem;
        justify-content: center;
        justify-self: center;
        align-items: center;
        position: relative;
        display: block;
        font-size: 55px;
        text-align: center;
        width: 100%;
        /* padding-left: inherit; */
        background-color: #fff;
        /* border-bottom: 1px solid #d4d4d4; */
        border-radius: 20px;
        z-index: 99;
        
      }
      
      .suggestion-active,
      .suggestions li:hover {
        background-color:whitesmoke;
        border-bottom: 2px solid #d4d4d4;
        border-radius: 20px;
        color: black;
        cursor: pointer;
        justify-content: center;
        justify-self: center;
        position: relative;
        font-weight: 700;
        z-index: 99;
      }
      
      .suggestions li:not(:last-child) {
        border-bottom: 2px solid #999;
        border-left: 1px solid #999;
        border-right: 1px solid #999;
        font-size: 21px;
        width: 95%;
        z-index: 99;
        padding-left: 1%;
      }
      .suggestions li:last-of-type {
        border-bottom: 2px solid #999;
        border-left: 1px solid #999;
        border-right: 1px solid #999;
        font-size: 21px;
        width: 95%;
        z-index: 99;
        padding-left: 1%;
      }
    }