.loading-portal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 1); /* semi-transparent black overlay */
    z-index: 1001; /* make sure the portal is on top of other elements */
  }
  
  .loading-animation {
    position: absolute;
  }
  
  .loading-spinner {
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.3); /* white with 30% opacity */
    border-top-color: #fff; /* white */
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite; /* animate the rotation */
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg); /* rotate the spinner 360 degrees */
    }
  }
  