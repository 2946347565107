/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.flights-list .accordion {
  border: 1px solid rgba(0, 0, 0, 0.9);
  border-radius: 2px;
  /* display: flex;
  justify-items: center;
  align-items: center;
  text-align: center; */
}

.flights-list .accordion__item + .accordion__item {
  border: 1px solid rgba(0, 0, 0, 0.0);
  
  width: 100%;
}

.flights-list .accordion__button {
  background-color: lightblue;
  color: black;
  cursor: pointer;
  padding: 21px;
  padding-bottom: 10%;
  width: 500px;
  text-align: left;
  border: none;
  font-weight: bold;
  border-radius: 1em;
}


.flights-list .accordion__button:focus{
  background-color: lightgoldenrodyellow;
  border-left: 2px solid #800080;
  border-right: 2px solid #800080;
  border-top: 2px solid #800080;
  border-bottom-left-radius: 0em;
  border-bottom-right-radius: 0em;
  
}

.flights-list .accordion__button:hover{
  background-color: lightgoldenrodyellow;
  border: 2px solid #800080;

}

/* .accordion__button:active {
  background-color: lightgreen;
} */

.flights-list .accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.flights-list .accordion__button[aria-expanded='true']::before,
.flights-list .accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
  background-color: red;
  font-size: x-large;

}

.flights-list .accordion__item[aria-expanded='true']:focus {

  background-color: purple;
  font-size: x-large;

}


[hidden] {
  display: none;
}

.flights-list .accordion__panel {
  border-left: 2px solid #800080;
  border-right: 2px solid #800080;
  border-bottom: 2px solid #800080;
  /* position: relative;
  margin-left: 4%;
  padding-bottom: 3%; */
  /* max-height:max-content;
  max-width: max-content; */
  width: 500px;
  padding: 21px;
  /* padding: 5.5%; */
  /* display: flexbox;
  flex-direction: column; */
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
  animation: fadein 0.35s ease-in;
}


.flights-inner-list .accordion {
  border: 1px solid rgba(0, 0, 0, 0.0);
  border-radius: 2px;
  /* display: flex;
  justify-items: center;
  align-items: center;
  text-align: center; */
}

/* .flights-inner-list .accordion__item + .accordion__item {
  border: 1px solid rgba(0, 0, 0, 0.0);
  
  width: 100%;
} */

.flights-inner-list .accordion__button {
  background-color: whitesmoke;
  color: black;
  cursor: pointer;
  padding: 3px;
  padding-bottom: 1%;
  padding-top: 1%;
  width: 450px;
  text-align: center;
  border: none;
  font-weight: bold;
  border-radius: 1em;
}


.flights-inner-list .accordion__button:focus{
  background-color: whitesmoke;
  border-left: 2px solid #800080;
  border-right: 2px solid #800080;
  border-top: 2px solid #800080;
  border-bottom-left-radius: 0em;
  border-bottom-right-radius: 0em;
  
}

.flights-inner-list .accordion__button:hover{
  background-color: whitesmoke;
  border: 2px solid #800080;

}

/* .accordion__button:active {
  background-color: lightgreen;
} */

.flights-inner-list .accordion__button:before {
  display: inline-block;
  content: '';
  /* height: 10px;
  width: 10px; */
  margin-right: 12px;
  /* border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor; */
  transform: rotate(-45deg);
}

.flights-inner-list .accordion__button[aria-expanded='true']::before,
.flights-inner-list .accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
  /* background-color: red; */
  font-size: x-large;

}

.flights-inner-list .accordion__item[aria-expanded='true']:focus {

  background-color: purple;
  font-size: x-large;

}


[hidden] {
  display: none;
}

.flights-inner-list .accordion__panel {
  border-left: 2px solid purple;
  border-right: 2px solid #800080;
  border-bottom: 2px solid #800080;
  /* position: relative;
  margin-left: 4%;
  padding-bottom: 3%; */
  background-color:whitesmoke;
  width: 414px;
  padding: 21px;
  margin-top: -3%;
  display: flexbox;
  overflow:auto;
  flex-direction: column;
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
  animation: fadein 0.35s ease-in;
}


.hotels-list .accordion {
  border: 1px solid rgba(0, 0, 0, 0.9);
  border-radius: 2px;
  /* display: flex;
  justify-items: center;
  align-items: center;
  text-align: center; */
}

/* .hotels-list .accordion__item + .accordion__item {
  border: 1px solid rgba(0, 0, 0, 0.0);
  
  width: 100%;
} */

.hotels-list .accordion__button {
  background-color: lightblue;
  color: black;
  cursor: pointer;
  padding: 21px;
  padding-bottom: 5%;
  width: 500px;
  text-align: left;
  border: none;
  font-weight: bold;
  border-radius: 1em;
}


.hotels-list .accordion__button:focus{
  background-color: lightgoldenrodyellow;
  border-left: 2px solid #800080;
  border-right: 2px solid #800080;
  border-top: 2px solid #800080;
  border-bottom-left-radius: 0em;
  border-bottom-right-radius: 0em;
  
}

.hotels-list .accordion__button:hover{
  background-color: lightgoldenrodyellow;
  border: 2px solid #800080;

}

/* .accordion__button:active {
  background-color: lightgreen;
} */

.hotels-list .accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.hotels-list .accordion__button[aria-expanded='true']::before,
.hotels-list .accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
  background-color: red;
  font-size: x-large;

}

.hotels-list .accordion__item[aria-expanded='true']:focus {

  background-color: purple;
  font-size: x-large;

}


[hidden] {
  display: none;
}

.hotels-list .accordion__panel {
  border-left: 2px solid #800080;
  border-right: 2px solid #800080;
  border-bottom: 2px solid #800080;
  /* position: relative;
  margin-left: 4%;
  padding-bottom: 3%; */
  width: 500px;
  padding: 21px;
  /* display: flexbox;
  flex-direction: column; */
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
  animation: fadein 0.35s ease-in;
}



/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}



.fineprint{
  font-size: 10pt;
}

.speedometer{
  width: 60%;
  height: 60%;
  display: flex;
  float: left;
  align-items: center;
  flex-direction: column;
}

.speedometer2{
  width: 100%;
  height: 100%;
}



.smallerratings{
  display: flex;
  /* float:right; */
  /* position: relative; */
  flex-direction: column;

}

.daybuttons{
  margin-top: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.heading-day-number{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;


  text-align: center;
}


.heading-day-number span{
  border: 2px solid purple;
  margin-top: 10%;
  border-radius: 0.5em;
  padding-left: 2%;  
  font-weight: bolder;
  font-size: xx-large;
  padding-right: 2%;
  width: max-content;
}


.layout {
  width: 100%;
  height: 100%;

  display: grid;
  grid:
    "time notes notes" 10%
    "details details details" 30%
    "opc opc opc" 30%
    / 25% 64% 0%;
  gap: 5% ;

}

.notes { grid-area: notes; border: 1px solid purple; text-align: center; height: max-content; font-size: medium; font-weight: bolder; font-family: cursive; background-color: whitesmoke;box-shadow: 0.2em 0.2em black;}
.time { grid-area: time; padding-top: 3%; border: 1px solid purple;text-align:center; height: max-content;  font-size: larger;  box-shadow: 0.2em 0.2em black;}
.details { grid-area: details; height: fit-content; border: 1px solid purple;font-size:small;text-align:center; justify-content: center; font-family:cursive; box-shadow: 0.25em 0.3em black;}
.opc{grid-area: opc; padding-left: 0%; margin-top: auto; height: max-content; background-color: transparent; border: 0px solid purple;}

.timeline {
  position: relative;
  max-width: 1200px;
  z-index: 0;
  margin: 0 auto;
  margin-top: -20%;

}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 0px;
  border: dashed green 2px ;
  background-color: whitesmoke;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  z-index: -1;
}

.list-test{
padding-left: 0%;
margin-top: 0%;
  max-height: max-content;
  max-width: fit-content;
  flex-direction: column;
  margin-left: 40%;
  width: 100%;
  /* scroll-behavior:smooth;
  scroll-snap-type:block mandatory;
  overflow-y: auto;
  overflow-x: hidden; */
  
 z-index: 999;

}
.list-test::-webkit-scrollbar-track {
  border: 1px solid goldenrod;
  padding: 2px 0;
  background-color: white;
}

.list-test::-webkit-scrollbar {
  width: 0px;
  border: dashed ;

}

.list-test::-webkit-scrollbar-thumb {
  border-radius: 10px;
  
  box-shadow: inset 0 0 0px purple;
  background-color: white;
  border: 1px solid purple;
}

.list-test ul div {
  /* border-radius: 50%; */
  /* border: 1px dashed black; */
  /* padding: 5px 10px; */
  margin-right: 10px;

  margin-left: -0%;

  background:#fff
}
.list-test ul div:before{
  content:'';
  position:absolute;
  border-left:1px solid white;
  left:14px;
  bottom:0;
  z-index:-1;
  height:100%
}

.list-test ul:last-child span:before{
 content:none;
}
.list-test ul:last-child{
  padding-bottom:0
}
.list-test ul {
  margin-top: 20em;
  height: 100%;
  width: 100%;
  margin-left: -30%;
  background-color: white;
  scroll-snap-align: center;
  /* border: purple solid 4px;
  border-radius: 3em; */
}
.list-test ul img {
  width: auto; 
  height: 100%;
}
.list-test ul:hover{
  background-color: lightgray;
  border: goldenrod solid 2px;
}
.list-test ul:active{
  background-color: lightgray;
  border: goldenrod solid 4px;
}
.list-test ul:focus{
  background-color: whitesmoke;
  border: goldenrod solid 4px;
}
.aboutus{
  background-color: whitesmoke;
  border: 2px solid purple;
  margin-top: 5%;
  margin-left: 10%;
  margin-right: 10%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 2em;
}
.map-container {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  height: 40%;
  width: 50%;
  display: block;
  border: 5px solid purple;
  /* -webkit-border-radius: 10%;
  -moz-border-radius: 10%;
  border-radius: 10%;
  -khtml-border-radius: 10%; */
  /* margin-left: 30%; */

}
.map-container:before{
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  display:inline-block;
    background: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  pointer-events: none;
  /* -webkit-border-radius: 10%;
  -moz-border-radius: 10%;
  border-radius: 10%;
  -khtml-border-radius: 10%; */
  overflow: hidden;
  transform: none;
   z-index: 999;
}


  .leaflet-container{
    /* border-radius: 5em ;
    -moz-border-radius:5em;
    -khtml-border-radius:5em;
    -webkit-border-radius:5em; */
    border-radius: 2em;
    border-radius: 2em;
    -khtml-border-border-radius: 2em;
    -khtml-border-border-radius: 2em;
    -moz-border-border-radius: 2em;
    -moz-border-border-radius: 2em;
    -webkit-border-border-radius: 2em;
    -webkit-border-border-radius: 2em;
    }
/* .map-container::content{
  -webkit-border-radius: 10%;
  -moz-border-radius: 10%;
  border-radius: 10%;
  -khtml-border-radius: 10%;
} */
.map-container .leaflet-routing-container-hide {
  display: none;
}
.map-outer-cover {
  margin-top: 2em;
  border-radius: 3em;
  background-color: white;
  /* border: 3px solid purple; */
  padding: 20px;
  margin-left: 7%;
  display: inline-block;
  z-index: 0;
  width: 90%;
  height:max-content;



}
.map-child {
  display:flex;
  position: fixed;
  width: 40%;
  align-content: center;
  /* float: left; */
  z-index: 0;
  padding-left:0%;
 justify-content: center;
}  
.map-child-list {
  display:inline;
  position: relative;
  width: 60%;
  height: 50%;
  justify-self: right;
  float: right;
 padding-top: 0%;
  padding-left:0%;
z-index: 0;

}  
.textbox-places{
  position: absolute;
  margin-bottom: 5%;
  height: 5%;
  width: 9%;
  border: 1px solid red;

}
body {
    background-image: url('background_full.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header{
  text-decoration: none;
  position: relative;
  /* background: linear-gradient(to bottom, rgba(211, 211, 211, 1), rgba(211, 211, 211, 0)); */
  padding-left: 5%;
  margin-top: 1%;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  /* border-bottom: 1px solid; */
  width: 50%;
}

.header a{
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  text-decoration: none;
}

.header a:hover{
 font-weight: bold;
 width: 21%;
}

.footer{
  margin-top: 20%;
  border: 0px;
  background-color: lightgray;
  background: linear-gradient(to bottom, rgba(211, 211, 211, 0), rgba(211, 211, 211, 1));
  max-height: fit-content;
  padding-top: 10%;
  position: relative;
  top: 95%;
  bottom: 0;
  left: 0;
  font-size: x-small;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  width: 100%;
  text-align: center;
  z-index: 8000;
}

/* .footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border: 0;
  background-color: lightgray;
  background: linear-gradient(to bottom, rgba(211, 211, 211, 0), rgba(211, 211, 211, 1));
  padding: 10px;
  font-size: x-small;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  text-align: center;
} */


.rightstuff-public{
  /* background: linear-gradient(to bottom, rgba(211, 211, 211, 1), rgba(211, 211, 211, 0)); */
  margin-top: -4%;
  text-align: right;
  margin-right: 10%;
  width:50%;
  padding-left: 45%;
}

.rightstuff-public-activated{
  margin-top: 0%;
  text-align: right;
  margin-right: 10%;
  width:50%;
  padding-left: 45%;
}

.rightstuff-public a {
  margin: 0 0.5em 0 0.5em;
  text-decoration: none;
  border: 2px solid;
  color: black;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  border-radius: 0.5em;
  border-color: purple;
  background-color: white;
  display: inline-block;
  padding-left: 0.5em;
  padding-bottom: 0.25em;
  padding-top: 0.25em;
  padding-right: 0.5em;
  box-shadow: 0.25em 0.3em black;
 }
 .rightstuff-public a:active{
  transform: translateY(5px);
 }
 .rightstuff-public a:hover{
  transform: translateY(2px);
 }

 .rightstuff-public-activated a {
  margin: 0 0.5em 0 0.5em;
  text-decoration: none;
  border: 2px solid;
  color: black;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  border-radius: 0.5em;
  border-color: purple;
  background-color: white;
  display: inline-block;
  padding-left: 0.5em;
  padding-bottom: 0.25em;
  padding-top: 0.25em;
  padding-right: 0.5em;
  box-shadow: 0.25em 0.3em black;
 }
 .rightstuff-public-activated a:active{
  transform: translateY(5px);
 }
 .rightstuff-public-activated a:hover{
  transform: translateY(2px);
 }

.logo-left{
  width:20%;
}




 @media (min-width:1401px) {
  .login{
   margin-top: 10%;
   text-align: center;
   border: 3px solid;
   width: 60%;
   display: flex;
   /* align-items: center; */
   justify-content: center;
   padding-top: 5%;
   padding-bottom: 5%;
   border-color: purple;
   border-radius: 3em;
   background-color: whitesmoke;
  }

  .register{
    margin-top: 5%;
    background-color: whitesmoke;
    text-align: center;
    border: 3px solid;
    border-radius: 3em;
    width: 70%;
    margin-left: 15%;
    align-self: center;
    padding-top: 3%;
    padding-bottom: 5%;
    border-color: purple;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  
   }

   .register div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
  }

   .register input{
    border: 2px solid purple;
    border-radius: 2em;
   }
 
  .tablelogin{

   width: 50%;
   margin-left: 25%;
   margin-right: auto;
   margin-top: 3%;
   margin-bottom: 3%;

  }
 
  .tablelogin td{
   padding-bottom: 3em;
   z-index: 100;
  }
 
  .submit-button{
   margin: 1em 2em 1em 2em;
  }
 
  .input-login{
   font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
   width: 95%;
   text-align: center;
   border-radius: 1em;
   margin-top: 1%;
   padding-top: 0.5em;
   padding-bottom: 0.5em;
   font-size: medium;

   border: solid 2px purple;
  }

  .note-register{
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    border: solid 1px;
    border-radius: 1.5rem;
    padding: 10px;
    max-width: fit-content;
  }

  .input-login-password{
    text-align: center;
    align-self: center;
    justify-self: center;
    background-color: white;
    border: solid 2px purple;
    border-radius: 1em;
    width: 100%;
    padding-left: -3%;
  }
 
  .input-login::selection{
   border-color: goldenrod;
  }
 
 /* Customize the label (the container) */
 .container {
   display: float;
   position: relative;
   padding-left: 35px;
   margin: 1em 1em 1em 1em;
   margin-bottom: 12px;
   cursor: pointer;
   font-size: 22px;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
 }
 
 /* Hide the browser's default radio button */
 .container input {
   position: absolute;
   opacity: 0;
   cursor: pointer;
   height: 0;
   width: 0;
 }
 
 /* Create a custom radio button */
 .checkmark {
   position: absolute;
   top: 0;
   left: 0;
   height: 25px;
   width: 25px;
   background-color: lightgoldenrodyellow;
   border: 2px solid;
   border-color:goldenrod;
   border-radius: 50%;
 }
 
 /* On mouse-over, add a grey background color */
 .container:hover input ~ .checkmark {
   background-color: purple;
 }
 
 /* When the radio button is checked, add a blue background */
 .container input:checked ~ .checkmark {
   background-color: white;
 }
 
 /* Create the indicator (the dot/circle - hidden when not checked) */
 .checkmark:after {
   content: "";
   position: absolute;
   display: none;
 }
 
 /* Show the indicator (dot/circle) when checked */
 .container input:checked ~ .checkmark:after {
   display: block;
 }
 
 /* Style the indicator (dot/circle) */
 .container .checkmark:after {
   top: 9px;
   left: 9px;
   width: 8px;
   height: 8px;
   border-radius: 50%;
   background: purple;
 }
  }
.login-canvas{
    position:absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

 }

 .full-height {
  height: 100%;
}

.autocomplete2 {
  /*the container must be positioned relative:*/
  position: relative;
  background: none;
  border-radius: 10px;
  /* border: red 1px solid; */
  width: 100%;
  height:100%;
  margin-top: 3%;
  display:flexbox;
  justify-content: center;
  align-items: center;
  background-color: none;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;;
  flex-wrap: wrap;
  font-size: 4px;
  padding-bottom: 0%;
  /* animation: 1s ease-out 0s 1 slideInFromLeft; */
}


/* @media (max-width:600px) {
.autocomplete2 .suggestions{
  display:block;
    width: 40%;
    margin-left: 30%;

  justify-content: center;
  justify-self: center;
  align-self: center;
  align-items: center;
  text-align: center;
  
}
} */
@media (min-width:1401px) {
  .autocomplete2 .suggestions{
    display:block;
      width: 40%;
      margin-left: 32%;
    /* border: green 4px solid; */
    justify-content: center;
    justify-self: center;
    align-self: center;
    align-items: center;
    text-align: center;
    
  }
  }
.autocomplete2 input[type=text]{
width: 45%;
height:40%;
border: green 4px solid;
border-radius: 10px;
/* font-family: Georgia, 'Times New Roman', Times, serif; */
font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
font-size: 18px;
text-align: center;
padding-top: 1%;
padding-bottom: 1%;
border: 2px solid;
border-color: purple;
box-shadow: 0.2em 0.2em black;
margin-top: 4%;
/* margin-bottom: 1%; */
/* display: flex;
justify-content: center;
align-items: center; */
}

.autocomplete2 input[type=submit]{
  width: 30%;
  height: 10%;
  background-color: gold;
  border: 1px solid;
  color: darkblue;
  border-radius: 10px;
  font-size: 19px;
  font-family: cursive;
  margin-top: 1%;
  margin-bottom: 2%;

}


.autocomplete {
  /*the container must be positioned relative:*/
  position: relative;
  background: white;
  border-radius: 10px;
  animation: 1s ease-out 0s 1 slideInFromLeft;
  width: 100%;
  height:80%;
  margin-top: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;;
  font-size: 4px;
}
.autocomplete input[type=text]{
width: 82%;
height:80%;
border-radius: 10px;
/* font-family: Georgia, 'Times New Roman', Times, serif; */
font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;;
font-size: 18px;
text-align: center;
padding-top: 1%;
padding-bottom: 1%;
border: 2px solid;
border-color: purple;
box-shadow: 0.2em 0.2em black;
margin-bottom: 1%;
display: flex;
justify-content: center;
align-items: center;
}

.autocomplete input[type=submit]{
  width: 30%;
  height: 7%;
  background-color: gold;
  border: 1px solid;
  color: darkblue;
  border-radius: 10px;
  font-size: 19px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;;
  margin-top: 3%;

}

/* CSS related to register.js */

.container-register {
  display: float;
  position: relative;
  padding-left: 25px;
  margin: 1em 1em 1em 1em;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-register input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.circle-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 2%;
  border-radius: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  margin: 0% 0% 0;
/* 
  border: solid 5px tomato; */
}
/* .circle-container > * {
  display:contents;
  position: absolute;
  border: 1px solid black;
  border-radius: 2em;

  font-size: smaller;
  width: 30%;
  height: 30%;

} */
/* .circle-container > *:nth-of-type(1) {
  transform: rotate(0deg) translate(14em) rotate(0deg);
}
.circle-container > *:nth-of-type(2) {
  transform: rotate(72deg) translate(10em) rotate(-72deg);
}
.circle-container > *:nth-of-type(3) {
  transform: rotate(144deg) translate(10em) rotate(-144deg);
}
.circle-container > *:nth-of-type(4) {
  transform: rotate(216deg) translate(10em) rotate(-216deg);
}
.circle-container > *:nth-of-type(5) {
  transform: rotate(288deg) translate(10em) rotate(-288deg);
} */
.circle-container label {
  display: block;
  width: 30%;
  height: 10%;
  align-items: center;
  padding-top: 1%;
  padding-bottom: 1%;
  align-content: center;
  border-radius: 2em;
  /* filter: grayscale(100%); */
  font-size: large;
  margin-top: 0%;
  margin-bottom: 1%;
  border: solid 2px purple;
  transition: 0.15s;
}
.circle-container label:hover{
  background-color: gold;
  border: 4px solid purple;
}
.circle-container label:has(input:checked):nth-child(1){
  background-color: gold;
  transform: translateY(00.2em);
  border: 4px solid purple;
}
.circle-container label:has(input:checked):nth-child(2){
  background-color: gold;
  transform: translateY(00.2em);
  border: 4px solid purple;
}
.circle-container label:has(input:checked):nth-child(3){
  background-color: gold;
  transform: translateY(00.2em);
  border: 4px solid purple;
}
.circle-container label:has(input:checked):nth-child(4){
  background-color: gold;
  transform: translateY(00.2em);
  border: 4px solid purple;
}
.circle-container label:has(input:checked):nth-child(5){
  background-color: gold;
  transform: translateY(00.2em);
  border: 4px solid purple;
}

.circle-container label li input[type="radio"]{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.circle-container label li input[type="radio"]:checked{
  background-color: gold;
  
}

.heading-h10{
  font-size:xx-large;
  color: purple;
  font-family:cursive;
}
.heading-h20{
  font-size: x-large;
  font-family: cursive;
}

.heading-h2-curve{
  font-size: x-large;
  font-family: cursive;
  font-weight: bolder;
  margin-left: 38%;
  margin-top: -12%;
  margin-bottom: -3%;
}

.heading-h1{
  animation: 1s ease-out 0s 1 slideInFromLeft;
  font-size: x-large;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  /* color: goldenrod; */
}
.heading-h1-user{
  padding: 10px;
  border-radius: 1rem;
  border: solid 3px purple;
  margin-bottom: 50px;
  background-color: whitesmoke;
  animation: 1s ease-out 0s 1 slideInFromLeft;
  font-size: x-large;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  /* color: goldenrod; */
}
.heading-h1-home{
  /* animation: 1s ease-out 0s 1 slideInFromLeft; */
  font-size: x-large;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.heading-h1-results{
  font-size: x-large;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.heading-h2{
  font-size: large;
  font-weight: bold;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}


.heading-h3{
  font-size: medium;
  font-weight: bold;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.heading-h5{
  font-size: small;
  font-weight: bold;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.qrcode {
  width: fit-content;
  position: inherit;
  display: flex;
  align-self: center;
  justify-self: center;
  align-content: center;
  /* padding-left: 28%; */
  border: 3px purple;
  border-width: 150%;

}

/* Loader animation */

.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: goldenrod;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* loader animation ends */
.loading-content{
top: 20%;
left: 50%;
margin-top: 20%;
margin-bottom: 0%;
position: relative;

}

@keyframes slideInFromLeft {
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideInFromLeftReverse {
  0% {
    transform: translateY(10%);
    transform: rotate(0);
  }
  100% {
    transform: translateY(0);
    transform: rotate(0);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-3-14 22:31:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
 @-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2023-3-14 22:39:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-out-expand-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes tracking-out-expand-fwd {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  60% {
    opacity: 0.8;
  }
  100% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    opacity: 0;
  }
}
@keyframes tracking-out-expand-fwd {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  60% {
    opacity: 0.8;
  }
  100% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    opacity: 0;
  }
}

.tracking-in-expand {
  display: flex;
  text-align: center;
  margin-left: -3%;
  font-size: 32px;
  font-family: cursive;
  font-weight: 300;
    -webkit-animation: tracking-in-expand 1.5s cubic-bezier(0.165, 0.840, 0.440, 1.000) 2s infinite ;
            animation: tracking-in-expand 1.5s cubic-bezier(0.165, 0.840, 0.440, 1.000) 2s infinite ;
/* 
              -webkit-animation: tracking-out-expand-fwd 2.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) 2s infinite both;
                      animation: tracking-out-expand-fwd 2.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) 2s infinite both; */

}

/* main logo on home page */
/* .main_image{
  image-resolution: 900dpi;
  transform: rotate(270deg);
  width: 20%;
  height: 20%;
  margin-left: 42%;
  margin-top: 5%;
  align-items: center;

} */
.main_image{
  image-resolution: 1000dpi;
  /* transform: rotate(270deg); */
  width: 21%;
  height: 21%;
  margin-left: 41%;
  margin-top: 0%;
  margin-bottom: -6.5%;
  align-items: center;
  /* animation: 1s ease-out 0s 1 slideInFromLeft; */
}

/* beta warning */
.beta_warning{

  padding-top: 1%;
  padding-left: 20%;
  padding-right: 20%;
  font-size: 12px;
  font-family:monospace;
  display: inline-block;
  color: black;
}

.beta{
  color: whitesmoke;
  font-size: 19px;
  margin-left: auto;
  margin-top:auto;
  text-align: center;
  font-family: Copperplate, Papyrus, fantasy;
  padding-top: 5%;
}
.home-logo{
  margin-top: 2%;
  text-align: center;
  margin-bottom: -5%;

}

.home-outer{
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
  
}

.home2-outer{
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.home2-outer-hotel{
  display: block;
  position: relative;
  justify-content: center;
  align-items: center;
  /* z-index: -1; */
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.home2-outer-user{
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.home2-outer-exit{
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 1s ease-in 0s 1 slideInFromLeftReverse;
}

.home2-credits{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-top: 3%;
  margin-bottom: -4%;
}

.home2{
  width: 60%;
  margin-top: 4.5%;
  border: 2px solid purple;
  border-radius: 3em;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 3%;
  animation: 1s ease-out 0s 1 slideInFromLeft;

}

.home2-user{
  background-color: whitesmoke;
  width: 60%;
  margin-top: 4.5%;
  border: 2px solid purple;
  border-radius: 3em;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 3%;
  animation: 1s ease-out 0s 1 slideInFromLeft;

}

.home2-hotels{
  width: 60%;
  margin-top: 4.5%;
  border: 2px solid purple;
  border-radius: 3em;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 3%;
  animation: 1s ease-out 0s 1 slideInFromLeft;
  z-index: 5000;
}



.home2-exit{
  width: 60%;
  margin-top: 4.5%;
  border: 2px solid purple;
  border-radius: 3em;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 3%;
  animation: 1s ease-out 0s 1 slideInFromLeft;

}

.home2-logo{
  margin-top: 2%;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: 1s ease-out 0s 1 slideInFromLeft;

}
.home2-logo-results{
  margin-top: 2%;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: 1s ease-out 0s 1 slideInFromLeft;

}

/* .register-1{
  margin-top: 30%;
} */

.autocomplete2-items {
  position: absolute;
  border-bottom: none;
  border-radius: 20px;
  border-top: none;
  z-index: 99;
  font-family:cursive;
  font-size: 19px;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
  
}
.autocomplete2-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
  border-radius: 20px;
}
.autocomplete2-items div:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}


.autocomplete-items {
  position: absolute;
  border-bottom: none;
  border-radius: 20px;
  border-top: none;
  z-index: 99;
  font-family:cursive;
  font-size: 19px;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}
.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
  border-radius: 20px;
}
.autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}
.pac-selected {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}
.pac-container {
    background-color: #fff;
    position: absolute!important;
    z-index: 1000;
    border-radius: 10px;
    border-top: 1px solid #d9d9d9;
    font-family: Arial, sans-serif;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden
}
.pac-item {
    cursor: default;
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #e6e6e6;
    border-radius: 20px;
    font-size: 11px;
    font-family: cursive;
    color: #999
}

.container-home2 {
  display: float;
  position: relative;
  /* padding-left: 25px; */
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

/* Hide the browser's default radio button */
.container-home2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container-home2 input[type='radio']:hover + figure{
  background-color: lightgoldenrodyellow;
  border: 3px solid purple;
}
.container-home2 input[type='radio']:checked + figure{
background-color: lightgoldenrodyellow;
border: 6px solid gold;
}

.container-home2 input[type='radio']:disabled + figure{
  background-color: lightgray;
  opacity: 40%;
  }

.menu-logo{
  display: inline-block;
  width: 100%;
  border-radius: 0.5em;
  /* box-shadow: 0.25em 0.3em black; */
}

.menu-figure{
  animation: 1s ease-out 0s 1 slideInFromLeft;
  display: inline-block;
  width: 20%;
  height: 25%;
  justify-content: center;
  align-content: center;
  text-align: center;
  align-self: center;
  border: 2px solid purple;
  border-radius: 0.5em;
  box-shadow: 0.25em 0.3em black;
}

.submit-home2{
  display: relative;
  margin-top: 5%;

}

.date-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* margin-left: 2%;
  padding-left: 2%; */

}

.date-container .react-datepicker-wrapper {
  margin: 0% 0%;
  width: 100%;
}
.date-container-individual{
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
   width: 300px;
  text-align: center;
}


.date-container .react-datepicker-wrapper input {
  display:inline-flex;
  margin-top: 2%;
  animation: 1s ease-out 0s 1 slideInFromLeft;
  width: 70%;
  border: 2px solid purple;
  border-radius: 0.5em;

}

.time-selector{
  display:inline-flex;
  width: 140px;
  margin-left: 5%;
  /* margin-top: 1%; */
  /* padding-top: 3%; */
}
.people-selector{
  animation: 1s ease-out 0s 1 slideInFromLeft;
  display:inline-flex;
  border: 2px solid purple;
  border-radius: 2em;
  margin-top: 2%;
  padding-top: 1%;
  padding-bottom: 3%;
  width: 50%;
  align-self: center;
  z-index: -1;
}
.npicker input{
  border: 2px solid purple;
  border-radius: 1em;
  width: 9%;
  z-index: -1;
}
.warning{
  color: red;
  margin-left: 20%;
}
.submit-home2-start{
  padding-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.maps-ol{
    height: 100%;
    width: 100%;
}

.flights-list{
  margin-top: 5%;
  margin-left: 5%;
  height:100%;
  display: flex;
  align-items: center;
  text-align: center;

  max-width: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100vh;
  flex-direction: column;
  padding-top: 2%;
  border: 2px solid purple;
  border-radius: 2em;
}
.flights-list-accordian aria-expanded{
  border: 2px solid green;
}

.flights-inner-list{
  margin-top: 0%;
  margin-left: 5%;
  height:100%;
  /* display: flex;
  align-items: center;
  text-align: center; */

  max-width: 100%;
  width: 100%;
  /* overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100vh;
  flex-direction: column; */
  padding-top: 2%;
  /* border: 2px solid purple; */
  /* border-radius: 2em; */
}

.hotels-list{
  margin-top: 5%;
  margin-left: 5%;
  height:100%;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: bold;
  position: relative;
  /* max-width: 70%; */
  width: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
  flex-direction: column;
  padding-top: 2%;
  border: 2px solid purple;
  border-radius: 2em; 
  z-index: 0;
  
}
.hotels-list-accordian aria-expanded{
  border: 2px solid green;
}

.gbp{
  /* justify-content: right; */
  margin-left: 5%;
  float: inline-end;
  text-indent: center;
  font-weight: bolder;
  border: 2px solid purple;
  background-color: white;
  padding: 2%;
  font-size: small;
  border-radius: 1em;
}

.gbp2{

  margin-left: 0%;
  
  float: inline-end;
margin-right: -2%;
  font-weight: bolder;
  font-size: small;
  border: 1.5px solid purple;
  background-color: white;
  padding: 1.5%;
  border-radius: 1em;
}

.gx{
  justify-content: right;
  margin-left: 0%;
  /* width:100%; */
  /* float: inline-end;
  text-indent: right; */
  font-weight: bolder;
  font-size:4px;
  background-color: white;
  padding: 1%;
  padding-right: 1.5%;
  border-radius: 1em;
  border: 2px solid purple;
}


.gp{
  display: inline-flex;
 float: right;
 vertical-align: middle;
 flex-direction: column;
  text-align: center;
  /* margin-left: 0%; */
  width:22%;
  /* float: inline-end;
  text-indent: right; */
  /* font-weight: bolder;
  font-size:4px;
  background-color: white;
  padding: 1%;
  padding-right: 1.5%;
  border-radius: 1em;
  border: 2px solid purple; */
}

.stops{
  justify-content:left;
  font-size:10px;
  font-weight:bold;
  margin-top:10%;
  margin-left: 20px;
}
.gz{
  justify-content: right;
  margin-left: 5%;
  /* width:100%; */
  /* float: inline-end;
  text-indent: right; */
  font-weight: normal;
  font-size:14px;
  /* background-color: white; */
  /* padding: 1%; */
  /* border-radius: 1em; */
  /* border: 2px solid purple; */
}

.gy{
  display:flex;
  position:relative;
  justify-content: left;
  margin-left: 5%;
  margin-top:-4%;
  /* width:100%; */
  /* float: inline-end;
  text-indent: right; */
  /* font-weight: normal;
  font-size:4px; */
  /* background-color: white; */
  /* padding: 1%; */
  /* border-radius: 1em; */
  /* border: 2px solid purple; */
}

.gw{
  display:inline-flex;
  position:relative;
  justify-content: left;
  font-size: 10px;
  max-width: min-content;
  margin-right: 0%;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  /* margin-left: 5%;
  margin-top:-4%; */
  /* width:100%; */
  /* float: inline-end;
  text-indent: right; */
  /* font-weight: normal;
  font-size:4px; */
  /* background-color: white; */
  /* padding: 1%; */
  /* border-radius: 1em; */
  /* border: 2px solid purple; */
}

.gs{
  display:inline-flex;
  position:relative;
  justify-content: left;
  font-size: 10px;
  max-width: min-content;
  margin-right: 0%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* margin-left: 5%;
  margin-top:-4%; */
  /* width:100%; */
  /* float: inline-end;
  text-indent: right; */
  /* font-weight: normal;
  font-size:4px; */
  /* background-color: white; */
  /* padding: 1%; */
  /* border-radius: 1em; */
  /* border: 2px solid purple; */
}
.extraday{
  font-size: 7px;
  margin-right: -12%;
  vertical-align: super;
  z-index: 1000;
}
.outwards{
  background-color: lightgray;
  padding: 0%;
  border-radius: 2em;
  margin-top: -1%;
}
.myDatePicker fieldset.MuiOutlinedInput-notchedOutline {
  border:  2px solid purple;
  color: purple;
}

.myDatePicker{
  color: #800080;
}

.myDatePicker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border-color: blue;
}
.hjk{
  margin-left: -7%;
}
.checkboxes label {
  display: inline-block;
  padding-right: 0px;
  white-space: nowrap;
}
.checkboxes input {
  vertical-align: middle;
  margin-left: -30%;
  transform: scale(1.5); 
}
.checkboxes label span {
  vertical-align: middle;
  margin-left: -15%;
}
.message{
  color: red;
  font-size: large;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  position: inherit;
  display: block;
  flex-direction: column;
  justify-content: center;

}
.message-register{
  color: black;
  max-width: 80%;
  font-size: large;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  position: inherit;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;

}

.pointsbar{
  border: 2px solid purple;
  background-color: whitesmoke;
  display: inline-flex;
  padding: 10px;
  margin-right: 5px;
  border-radius: 1em;
  box-shadow: 0.25em 0.3em black;
  /* box-shadow: outset 0.1em 0.1em black; */
  text-align: center;
  vertical-align: middle;
}

.moolah{
  margin-left: 10px;
  margin-top: 2px;
  /* padding-left: 10px; */
  /* font-family: cursive; */
}

.ads{
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: center;
  align-items: center;
  
}

.return-date{
  margin-bottom: 5%;
}

/* .dropdown-menu{
  border: 2px solid purple;
  z-index: 5000;
  display: flex;
  height: max-content;
  padding: 10%;
  width:max-content;
  background-color: whitesmoke;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.dropdown button{
  border: 2px solid purple;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: large;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0.5em;
  z-index: 5000;
}

.dropdown{
  z-index: 4999;
} */

.dropdown {
  position: relative;
}

.dropdown button {
  /* background-color: #fff;
  border: 1px solid #ddd;
  color: #333; */
  border: 2px solid purple;
  background-color: white;
  box-shadow: 0.25em 0.3em black;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: large;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0.5em;
  cursor: pointer;
  padding: 8px 16px;
}

.dropdown button:hover {
  /* background-color: #fff;
  border: 1px solid #ddd;
  color: #333; */
  border: 2px solid purple;
  background-color: white;
  box-shadow: 0.25em 0.3em black;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: large;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0.5em;
  cursor: pointer;
  transform: translateY(2px);
  padding: 8px 16px;
  
}

.dropdown .dropdown-menu {
  background-color: #fff;
  border: 2px solid purple;
  /* border-top: none; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  left: 0;
  position: absolute;
  top: 100%;
  width: 70%;
  
  height: 20vh;
  margin-top: 2%;
  padding: 5%;
  /* width:max-content; */
  background-color: whitesmoke;
  border-radius: 2em;
  box-shadow: 0.2em 0.25em black;


  z-index: 1;
}

.dropdown .dropdown-menu::after{
  z-index: 0;
}

.dropdown .dropdown-menu > * {
  padding: 8px 16px;
  z-index: 0;
}

.dropdown .dropdown-menu > *:hover {
  background-color: #f5f5f5;
}

.dropdown .dropdown-menu button {

  height: 25%;
  margin-top: 5%;
  margin-bottom: 10%;
}

/* .checklistbkg{
  background-image: url('bkg.webp');
  background-size: 100%;
} */
 /* .App .checklistbkg{
  background-image: url('bkg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

} */

.checklist-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  background-color: whitesmoke;
  text-align: center;
  /* text-align: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center; */
  /* align-self: center; */
  border: 2px solid purple;
  border-radius: 10px;
  padding: 20px;
  max-width: 600px;
  height: 50vh;
  margin: 0 auto;
  margin-top: 5%;
  opacity: 1;
  animation: fade-in 0.5s ease-in-out forwards;
}

.checklist-container-homepage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  background-color: whitesmoke;
  text-align: center;
  /* text-align: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center; */
  /* align-self: center; */
  border: 2px solid purple;
  border-radius: 10px;
  padding: 20px;
  max-width: 600px;
  height: 60vh;
  margin: 0 auto;
  margin-top: 7%;
  width: 80%;
  opacity: 1;
  animation: fade-in 0.5s ease-in-out forwards;
}

@keyframes fade-in {
  0% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0%);
  }
}

.checklist-container h3 {
  margin-top: 0;
  animation: fade-in 0.5s ease-in-out forwards;
}

.checklist-container div {
  /* display: flex; */
  margin-bottom: 10px;
  animation: fade-in 0.5s ease-in-out forwards;
  position: relative;
  /* align-items: center; */
  /* align-content: center;
  text-align: center;
  justify-self: center;
  align-self: center; */
}

/* .checklist-container label {
  display: block;
  margin-bottom: 5px;
  animation: fade-in 0.5s ease-in-out forwards;
} */
.checklist-container div form{
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.checklist-container label {
  display: flex;
  margin-bottom: 10px;
  animation: fade-in 0.5s ease-in-out forwards;
  padding: 5px;
  border: 2px solid purple;
  border-radius: 1em;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  background-color: rgba(206, 224, 211, 0.2);
  transition: background-color 0.3s;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  width:200px;
}

/* .checklist-container label::before {
  content: "";
  position: flex;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 10px;
  height: 20px;
  background-color: white;
  border: 2px solid purple;
  border-radius: 50%;
  display: none;
} */

.checklist-container label:hover {
  background-color: #A5D6A7;
}

.checklist-container label:hover::before {
  background-color: #4CAF50;
}

.checklist-container label input {
  position: relative;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checklist-container label:has(input:checked){
    background-color: #4CAF50;
  }

/* .checklist-container label:active input:active{
  background-color: #4CAF50;
}
  /* .checklist-container label input:checked + ::before {
    background-color: #2E7D32;
    display: block;
    content: "";
  } */
  
  /* .checklist-container input[type="checkbox"]:checked + label {
    background-color: #4CAF50; */

  .checklist-container input[type="checkbox"]:checked + label::after,
  .checklist-container input[type="radio"]:checked + label::after {
    display: block;
    background-color: #2E7D32;
    content: "";
  }

.checklist-container div button{
  display: flex;
  position: relative;
  align-self: center;
}

.checklist-container button {
  margin-top: 10px;
  /* animation: fade-in 0.5s ease-in-out forwards; */
}

.checklist-container ButtonSelect{
  display: flex;
  justify-self: center;
}
.foreground-image {
  position: relative;
  top: 0%;
  left: 0%;
  transform: translateX(-50%);
  width: 70%;
  max-height: 55%;
  z-index: -3;
}
.startingpage{
  text-align: center;
  width: 33%;
}
/* Add different backgrounds for each question */
/* .checklist-container:nth-child(1) {
  background-image: url('question1-background.jpg');
  background-size: cover;
}

.checklist-container:nth-child(2) {
  background-image: url('question2-background.jpg');
  background-size: cover;
}

.checklist-container:nth-child(3) {
  background-image: url('question3-background.jpg');
  background-size: cover;
} */


/* .filter{
z-index: 5000;
} */