/* styles.css */

.landing-page {
    background-color: none;
    display: flex;
    flex-direction: column;
    /* place-items: center center; */
    height: 200vh;
    margin-top: 2%;
    z-index: -2;


  }

  .media-section {
    position: relative;
    height: 48%;
    display: flex;
    place-items: center center;
    place-content: center center;
    align-items: center center;
    text-align: center center;
    justify-items: center center;
    justify-content: center center;
    align-content: center center;

  }
  /* .media-section img{
    align-self: center;
  }
   */
  .background-video {
    display: flex;
    position: absolute;
    /* top: 10%; */
    /* left: 0; */
    width: 70%;
    height: 70%;
    /* object-fit: cover; */
    z-index: 0;
    border-radius: 4em; 
    object-fit: cover;
    border: 5px solid purple;
  }
  .foreground-image {
    position: absolute; /* Change from "inherit" to "absolute" */
    top: 34.1%; /* Adjust the vertical position as needed */
    left: 50%; /* Adjust the horizontal position as needed */
    transform: translate(-50%, -50%); /* Center the image */
    max-width: 50%; /* Allow the image to resize within its container */
    max-height: 45%;
    z-index: 0;
    border-bottom-left-radius: 4em;
    border-bottom-right-radius: 4em;
    border: 7px solid gold;
    background-color: white;
    padding: 10px;
  }
  .media-section {
  position: relative;
  height: 48vh; /* Use viewport height instead of percentage */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.background-video {
  display: flex;
  position: absolute;
  width: 70%;
  height: 70%;
  z-index: 0;
  border-radius: 4em;
  object-fit: cover;
  border: 5px solid purple;
}

.foreground-image {
  position: absolute;
  bottom: 0; /* Position the image at the bottom */
  left: 50%;
  transform: translateX(-50%);
  max-width: 726px;
  max-height: 1080px;
  z-index: 0;
  border-radius: 2em;
  /* border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em; */
}
  .title {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 50;
    color: goldenrod;
    background-color: whitesmoke;
    border-radius: 1rem;
    padding-left: 30px;
    border: 3px solid gold;
    padding-bottom: 4px;
    padding-right: 30px;
    margin-top: -6%;
    text-shadow: 1px 1px #000000;
    font-size: 1.5rem;
    z-index: 2;
  }
  
  .bottom-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40%;
    padding: 1rem;
  }

  
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 28%;
    width: 28%;
    border: 3px solid purple;
    border-radius: 2em;
    color: black;
    padding: 0.9rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-align: center;
    height: 15rem;
    background-color: whitesmoke;
  }
  
  .logo {
    width: 80px;
    height: 80px;
  }
  
  .text {
    margin-top: 1.5rem;
    text-align: center;
    color: black;
    font-weight: bolder;
  }

  .textsmall {
    font-size: small;
  
  }
  .carousel {
    top: 40%;
    border-radius: 2em;
    border: 5px solid gold;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3) inset; /* Add linear shading effect */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2));
    position: relative;
    width: 50%;
    
    height: 135%; /* Adjust height as needed */
    overflow: hidden;
    margin-bottom: 10%;
    margin-top: 0%;
  }
  .main_image_landing{
    image-resolution: 1000dpi;
    /* transform: rotate(270deg); */
    width: 20%;
    height: 140%; 
    margin-left: 41%;
    margin-top: -6%;
    margin-bottom: -3.5%;
    align-items: center;
    /* animation: 1s ease-out 0s 1 slideInFromLeft; */
  }
  .carousel-items {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-item {
    flex: 0 0 100%;
    position: relative;
    padding-top: 70%/*56.25%; /* 16:9 aspect ratio (9 / 16 = 0.5625) */
  }
  
  .carousel-item.active {
    opacity: 1;
  }
  
  .carousel-item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .carousel-button {
    position: absolute;
    top: 87%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    background-color: whitesmoke;
    border: 3px solid gold;
    box-shadow: 0.3em 0.3em black;
    border-radius: 2rem;
    color: #000000;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    z-index: 1;
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    color: #ffffff;
    font-size: 46px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    z-index: 1;
  }
  
  .previous {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }
  @media (max-width:600px) {
    .media-section {
      position: relative;
      height: 48vh; /* Use viewport height instead of percentage */
      margin-bottom: 20vh;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .bottom-section {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 40%;
      padding: 1rem;
      margin-top: 30%;
    }

    .section {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 75%;
      width: 75%;
      margin-top: 5vh;
      border: 3px solid purple;
      border-radius: 2em;
      color: black;
      padding: 0.9rem;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      text-align: center;
      height: 20rem;
      background-color: whitesmoke;
    }
    .title {
      position: absolute;
      top: 15%;
      left: 50%;
      transform: translateX(-50%);
      font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      font-weight: 50;
      color: goldenrod;
      background-color: whitesmoke;
      border-radius: 1rem;
      padding-left: 30px;
      border: 3px solid gold;
      padding-bottom: 4px;
      padding-right: 30px;
      margin-top: -3%;
      text-shadow: 1px 1px #000000;
      font-size: 1.2rem;
      z-index: 2;
    }
    
    .carousel {
      top: 40%;
      border-radius: 2em;
      border: 5px solid gold;
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3) inset; /* Add linear shading effect */
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2));
      position: relative;
      width: 85%;
      
      height: 80%; /* Adjust height as needed */
      overflow: hidden;
      margin-bottom: 50%;
      margin-top: 10%;
    }
    .main_image_landing{
      image-resolution: 1000dpi;
      /* transform: rotate(270deg); */
      width: 30%;
      height: 115%; 
      margin-left: 38%;
      margin-top: 0%;
      margin-bottom: -3.5%;
      align-items: center;
      /* animation: 1s ease-out 0s 1 slideInFromLeft; */
    }
    .carousel-items {
      display: flex;
      transition: transform 0.5s ease-in-out;
    }
    
    .carousel-item {
      flex: 0 0 100%;
      position: relative;
      padding-top: 70%/*56.25%; /* 16:9 aspect ratio (9 / 16 = 0.5625) */
    }
    
    .carousel-item.active {
      opacity: 1;
    }
    
    .carousel-item img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .carousel-button {
      position: absolute;
      top: 87%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 5px 5px;
      background-color: whitesmoke;
      border: 3px solid gold;
      box-shadow: 0.3em 0.3em black;
      border-radius: 2rem;
      color: #000000;
      text-decoration: none;
      border-radius: 5px;
      font-weight: bold;
      z-index: 1;
      width: fit-content;
      font-size: 0.7rem;
    }
    
    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      color: #ffffff;
      font-size: 46px;
      font-weight: 500;
      border: none;
      cursor: pointer;
      z-index: 1;
    }
    
    .previous {
      left: 0px;
    }
    
    .next {
      right: 0px;
    }
  }

  @media (max-width:375px) {
    .media-section {
      position: relative;
      height: 48vh; /* Use viewport height instead of percentage */
      margin-bottom: 20vh;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .bottom-section {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 40%;
      padding: 1rem;
      margin-top: 30%;
    }

    .section {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 75%;
      width: 75%;
      margin-top: 5vh;
      border: 3px solid purple;
      border-radius: 2em;
      color: black;
      padding: 0.9rem;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      text-align: center;
      height: 20rem;
      background-color: whitesmoke;
    }
    .title {
      position: absolute;
      top: 15%;
      left: 50%;
      transform: translateX(-50%);
      font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      font-weight: 50;
      color: goldenrod;
      background-color: whitesmoke;
      border-radius: 1rem;
      padding-left: 30px;
      border: 3px solid gold;
      padding-bottom: 4px;
      padding-right: 30px;
      margin-top: -3%;
      text-shadow: 1px 1px #000000;
      font-size: 1.1rem;
      width: fit-content;
      z-index: 2;
    }
    
    .carousel {
      top: 40%;
      border-radius: 2em;
      border: 5px solid gold;
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3) inset; /* Add linear shading effect */
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2));
      position: relative;
      width: 85%;
      
      height: 65%; /* Adjust height as needed */
      overflow: hidden;
      margin-bottom: 10%;
      margin-top: -30%;
    }
    .main_image_landing{
      image-resolution: 1000dpi;
      /* transform: rotate(270deg); */
      width: 30%;
      height: 115%; 
      margin-left: 38%;
      margin-top: 0%;
      margin-bottom: -3.5%;
      align-items: center;
      /* animation: 1s ease-out 0s 1 slideInFromLeft; */
    }
    .carousel-items {
      display: flex;
      transition: transform 0.5s ease-in-out;
    }
    
    .carousel-item {
      flex: 0 0 100%;
      position: relative;
      padding-top: 70%/*56.25%; /* 16:9 aspect ratio (9 / 16 = 0.5625) */
    }
    
    .carousel-item.active {
      opacity: 1;
    }
    
    .carousel-item img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .carousel-button {
      position: absolute;
      top: 87%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 10px 20px;
      background-color: whitesmoke;
      border: 3px solid gold;
      box-shadow: 0.3em 0.3em black;
      border-radius: 2rem;
      color: #000000;
      text-decoration: none;
      border-radius: 5px;
      font-weight: bold;
      z-index: 1;
      width: fit-content;
      font-size: 0.7rem;
    }
    
    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      color: #ffffff;
      font-size: 46px;
      font-weight: 500;
      border: none;
      cursor: pointer;
      z-index: 1;
    }
    
    .previous {
      left: 0px;
    }
    
    .next {
      right: 0px;
    }
  }
  

  @media (max-width:1000px) and (min-width:600px) {
    .media-section {
      position: relative;
      height: 48vh; /* Use viewport height instead of percentage */
      margin-bottom: 20vh;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .bottom-section {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 40%;
      padding: 1rem;
      margin-top: 40%;
    }

    .section {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 75%;
      width: 75%;
      margin-top: 5vh;
      border: 3px solid purple;
      border-radius: 2em;
      color: black;
      padding: 0.9rem;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      text-align: center;
      height: 20rem;
      background-color: whitesmoke;
    }
    .title {
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translateX(-50%);
      font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      font-weight: 50;
      color: goldenrod;
      background-color: whitesmoke;
      border-radius: 1rem;
      padding-left: 30px;
      border: 3px solid gold;
      padding-bottom: 4px;
      padding-right: 30px;
      margin-top: -18%;
      text-shadow: 1px 1px #000000;
      font-size: 1.2rem;
      z-index: 2;
    }
    
    .carousel {
      top: 40%;
      border-radius: 2em;
      border: 5px solid gold;
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3) inset; /* Add linear shading effect */
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2));
      position: relative;
      width: 90%;
      
      height: 210%; /* Adjust height as needed */
      overflow: hidden;
      margin-bottom: 20%;
      margin-top: 0%;
    }
    .main_image_landing{
      image-resolution: 1000dpi;
      /* transform: rotate(270deg); */
      width: 30%;
      height: 80%; 
      margin-left: 38%;
      margin-top: -5%;
      margin-bottom: 10%;
      align-items: center;
      /* animation: 1s ease-out 0s 1 slideInFromLeft; */
    }
    .carousel-items {
      display: flex;
      transition: transform 0.5s ease-in-out;
    }
    
    .carousel-item {
      flex: 0 0 100%;
      position: relative;
      padding-top: 70%/*56.25%; /* 16:9 aspect ratio (9 / 16 = 0.5625) */
    }
    
    .carousel-item.active {
      opacity: 1;
    }
    
    .carousel-item img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .carousel-button {
      position: absolute;
      top: 75%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 5px 5px;
      background-color: whitesmoke;
      border: 3px solid gold;
      box-shadow: 0.3em 0.3em black;
      border-radius: 2rem;
      color: #000000;
      text-decoration: none;
      border-radius: 5px;
      font-weight: bold;
      z-index: 1;
      width: fit-content;
      font-size: 1.2rem;
    }
    
    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      color: #ffffff;
      font-size: 46px;
      font-weight: 500;
      border: none;
      cursor: pointer;
      z-index: 1;
    }
    
    .previous {
      left: 0px;
    }
    
    .next {
      right: 0px;
    }
    .footer{
      margin-top: 80%;
      border: 0px;
      background-color: lightgray;
      background: linear-gradient(to bottom, rgba(211, 211, 211, 0), rgba(211, 211, 211, 1));
      max-height: fit-content;
      padding-top: 10%;
      position: relative;
      top: 99%;
      bottom: 0;
      left: 0;
      font-size: x-small;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      width: 100%;
      text-align: center;
      z-index: 8000;
    }
  }
  @media (min-width:1500px) {
    .main_image_landing{
      image-resolution: 1000dpi;
      /* transform: rotate(270deg); */
      width: 20%;
      height: 100%; 
      margin-left: 41%;
      margin-top: -2%;
      margin-bottom: 0%;
      align-items: center;
      /* animation: 1s ease-out 0s 1 slideInFromLeft; */
    }
    /* .App {
      text-align: center;

      max-height: 90vh;
    } */
    .footer{
      margin-top: 0%;
      border: 0px;
      background-color: lightgray;
      background: linear-gradient(to bottom, rgba(211, 211, 211, 0), rgba(211, 211, 211, 1));
      max-height: fit-content;
      padding-top: 0%;
      position: relative;
      top: 40%;
      bottom: 0;
      left: 0;
      font-size: x-small;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      width: 100%;
      text-align: center;
      z-index: 8000;
    }
    .bottom-section {
      display: flex;
      /* flex-direction: column;
      justify-content: space-around; */
      align-items: center;
      height: 40%;
      padding: 1rem;
      margin-top: 0%;
    }

  }
